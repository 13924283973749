import React from 'react'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

import { Link } from 'gatsby'
import buttonOutlinedGatsbyLinkStyle from './buttonOutlinedGatsbyLinkStyle'
// @material-ui/icons


const ButtonOutlinedGatsbyLink = props => {

  const { classes } = props

  return (
    <Link
      to={props.link}
    >
      <div className={classes.root}>
        {props.title}
      </div>
    </Link>


  )

}

export default withStyles(buttonOutlinedGatsbyLinkStyle)(ButtonOutlinedGatsbyLink)
