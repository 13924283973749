import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'

import Footer from 'components/Footer/Footer.jsx'

import { graphql, useStaticQuery } from 'gatsby'
import Header from '../../components/Header/Header'
import errorPageStyle from './errorPageStyle'
import BlogSection from '../../elements/RecentBlogPosts/RecentBlogPosts'
import InstagramSection from '../../components/InstagramSection/InstagramSection'
import SpainHolidaysCta from '../../elements/SpainholidaysCta/SpainHolidaysCta'
import Section from '../../components/Section/Section'
import ButtonOutlinedGatsbyLink from '../../components/ButtonOutlinedGatsbyLink/ButtonOutlinedGatsbyLink'

const ErrorPage = props => {

  const data = useStaticQuery(graphql`
    query {
  
      datoCmsHome{
        spainholidayBanner{
          title
          subtitle
          buttonLink
          buttonTitle
        }
      }
    }
  `)

  const { classes} = props

  return (
    <div className={classes.backgroundColor}>
      <Header
        SEOtitle={"404"}
        SEOdescription={"Seite nicht gefunden"}

      />
      <Section>
        <h1 className={classes.H1}> 404 - Seite existiert nicht</h1>
        <ButtonOutlinedGatsbyLink
          title={'Home'}
          link={'/'}
        />
      </Section>


      <BlogSection/>
      <InstagramSection/>
      <SpainHolidaysCta
        title={data.datoCmsHome.spainholidayBanner[0].title}
        subtitle={data.datoCmsHome.spainholidayBanner[0].subtitle}
        buttonTitle={data.datoCmsHome.spainholidayBanner[0].buttonTitle}
        buttonLink={data.datoCmsHome.spainholidayBanner[0].buttonLink}
      />
      <Footer/>
    </div>
  )

}

export default withStyles(errorPageStyle)(ErrorPage)
